.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

.ant-btn {
  letter-spacing: 1px;
  border-radius: 3px;
  border: solid 1px #ced4da;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  background: #fff;
  border: solid 1px #226ca0;
  color: #226ca0;
}

.ant-btn-primary {
  background: #226ca0;
  border: solid 1px #226ca0;
}

.ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active {
  border-color: #1e5e8b;
  background: #1e5e8b;
}

.btn-primary-outline {
  color: #226ca0;
  border: solid 1px #226ca0;
  background: #fff;
}

.btn-primary-outline:hover, .btn-primary-outline:focus, .btn-primary-outline:active {
  background: #226ca0;
  border: solid 1px #226ca0;
  color: #fff;
}

.btn-secondary-outline {
  color: #ff6720;
  border: solid 1px #ff6720;
  background: #fff;
}

.btn-secondary-outline:hover, .btn-secondary-outline:focus, .btn-secondary-outline:active {
  background: #ff6720;
  border: solid 1px #ff6720;
  color: #fff;
}

.btn-success-outline {
  color: #86d295;
  border: solid 1px #86d295;
  background: #fff;
}

.btn-success-outline:hover, .btn-success-outline:focus, .btn-success-outline:active {
  background: #86d295;
  border: solid 1px #86d295;
  color: #fff;
}

.btn-info-outline {
  color: #17a2b8;
  border: solid 1px #17a2b8;
  background: #fff;
}

.btn-info-outline:hover, .btn-info-outline:focus, .btn-info-outline:active {
  background: #17a2b8;
  border: solid 1px #17a2b8;
  color: #fff;
}

.btn-warning-outline {
  color: #ffc107;
  border: solid 1px #ffc107;
  background: #fff;
}

.btn-warning-outline:hover, .btn-warning-outline:focus, .btn-warning-outline:active {
  background: #ffc107;
  border: solid 1px #ffc107;
  color: #fff;
}

.btn-danger-outline {
  color: #f40000;
  border: solid 1px #f40000;
  background: #fff;
}

.btn-danger-outline:hover, .btn-danger-outline:focus, .btn-danger-outline:active {
  background: #f40000;
  border: solid 1px #f40000;
  color: #fff;
}

.btn-light-outline {
  color: #f8f9fa;
  border: solid 1px #f8f9fa;
  background: #fff;
}

.btn-light-outline:hover, .btn-light-outline:focus, .btn-light-outline:active {
  background: #f8f9fa;
  border: solid 1px #f8f9fa;
  color: #fff;
}

.btn-dark-outline {
  color: #343a40;
  border: solid 1px #343a40;
  background: #fff;
}

.btn-dark-outline:hover, .btn-dark-outline:focus, .btn-dark-outline:active {
  background: #343a40;
  border: solid 1px #343a40;
  color: #fff;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #226ca0 !important;
}

.border-secondary {
  border-color: #ff6720 !important;
}

.border-success {
  border-color: #86d295 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #f40000 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-60 {
    height: 60% !important;
  }
  .h-sm-70 {
    height: 70% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-80 {
    height: 80% !important;
  }
  .h-sm-85 {
    height: 85% !important;
  }
  .h-sm-90 {
    height: 90% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-60 {
    height: 60% !important;
  }
  .h-md-70 {
    height: 70% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-80 {
    height: 80% !important;
  }
  .h-md-85 {
    height: 85% !important;
  }
  .h-md-90 {
    height: 90% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .h-lg-70 {
    height: 70% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-80 {
    height: 80% !important;
  }
  .h-lg-85 {
    height: 85% !important;
  }
  .h-lg-90 {
    height: 90% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-60 {
    height: 60% !important;
  }
  .h-xl-70 {
    height: 70% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-80 {
    height: 80% !important;
  }
  .h-xl-85 {
    height: 85% !important;
  }
  .h-xl-90 {
    height: 90% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #226ca0 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #195076 !important;
}

.text-primary a {
  color: #226ca0 !important;
}

.text-primary a:hover, .text-primary a:focus, .text-primary a:active {
  color: #1b557e !important;
}

.text-secondary {
  color: #ff6720 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #ec4b00 !important;
}

.text-secondary a {
  color: #ff6720 !important;
}

.text-secondary a:hover, .text-secondary a:focus, .text-secondary a:active {
  color: #f64e00 !important;
}

.text-success {
  color: #86d295 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #61c474 !important;
}

.text-success a {
  color: #86d295 !important;
}

.text-success a:hover, .text-success a:focus, .text-success a:active {
  color: #68c77b !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-info a {
  color: #17a2b8 !important;
}

.text-info a:hover, .text-info a:focus, .text-info a:active {
  color: #128294 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-warning a {
  color: #ffc107 !important;
}

.text-warning a:hover, .text-warning a:focus, .text-warning a:active {
  color: #dda600 !important;
}

.text-danger {
  color: #f40000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c10000 !important;
}

.text-danger a {
  color: #f40000 !important;
}

.text-danger a:hover, .text-danger a:focus, .text-danger a:active {
  color: #cb0000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-light a {
  color: #f8f9fa !important;
}

.text-light a:hover, .text-light a:focus, .text-light a:active {
  color: #e0e5e9 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-dark a {
  color: #343a40 !important;
}

.text-dark a:hover, .text-dark a:focus, .text-dark a:active {
  color: #222629 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.fill-primary {
  fill: #226ca0 !important;
}

.fill-secondary {
  fill: #ff6720 !important;
}

.fill-success {
  fill: #86d295 !important;
}

.fill-info {
  fill: #17a2b8 !important;
}

.fill-warning {
  fill: #ffc107 !important;
}

.fill-danger {
  fill: #f40000 !important;
}

.fill-light {
  fill: #f8f9fa !important;
}

.fill-dark {
  fill: #343a40 !important;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

.bg-primary {
  background: #226ca0 !important;
}

.bg-secondary {
  background: #ff6720 !important;
}

.bg-success {
  background: #86d295 !important;
}

.bg-info {
  background: #17a2b8 !important;
}

.bg-warning {
  background: #ffc107 !important;
}

.bg-danger {
  background: #f40000 !important;
}

.bg-light {
  background: #f8f9fa !important;
}

.bg-dark {
  background: #343a40 !important;
}

body .notification-dropdown .ant-dropdown-menu {
  margin-top: 15px;
  position: relative;
}

body .notification-dropdown .ant-dropdown-menu:before {
  position: absolute;
  top: -8px;
  right: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-opposite-position(top): 12px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

body .ant-popover-inner-content .help-text {
  max-width: 460px;
}

body .ant-popover-inner-content .help-text h4 {
  font-size: 16px;
}

body .ant-popover-inner-content .help-text ul {
  margin: 0;
  padding: 0;
}

body .ant-popover-inner-content .help-text ul li {
  list-style: none;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}

body .ant-popover-inner-content .help-text ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  width: 15px;
  height: 2px;
  background: rgba(0, 0, 0, 0.65);
}

body .ant-btn {
  font-weight: bold;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  letter-spacing: 0;
  height: auto;
  padding: 6px 12px;
}

body .ant-btn.ant-btn-lg {
  padding: 12px 15px;
  font-size: 16px;
  border-radius: 4px;
  line-height: 1;
  height: auto;
}

body .ant-btn.ant-btn-primary {
  color: #fff;
  background-color: #226ca0;
  border-color: #226ca0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

body .ant-btn.ant-btn-primary:hover, body .ant-btn.ant-btn-primary:focus, body .ant-btn.ant-btn-primary:active {
  border-color: #1e5e8b;
  background: #1e5e8b;
  -webkit-box-shadow: 0px 10px 10px -10px rgba(34, 108, 160, 0.4);
          box-shadow: 0px 10px 10px -10px rgba(34, 108, 160, 0.4);
}

body .ant-btn.ant-btn-link {
  border: none;
  background: none;
  color: #212529;
}

body .ant-btn.ant-btn-link:hover, body .ant-btn.ant-btn-link:focus, body .ant-btn.ant-btn-link:active {
  border: none;
  background: none;
}

body .ant-form-item {
  position: relative;
}

body .ant-form-item .has-error .ant-form-explain,
body .ant-form-item .has-error .ant-form-split {
  text-align: left;
  color: #f40000;
  position: absolute;
  left: 0;
  font-size: 12px;
  bottom: -20px;
}

body .ant-input {
  border: solid 1px #ced4da;
}

body .ant-input:hover, body .ant-input:focus, body .ant-input:active {
  border: solid 1px #226ca0;
  -webkit-box-shadow: 0 0 0 2px rgba(34, 108, 160, 0.2);
          box-shadow: 0 0 0 2px rgba(34, 108, 160, 0.2);
}

body input:-webkit-autofill,
body input:-webkit-autofill:hover,
body input:-webkit-autofill:focus,
body textarea:-webkit-autofill,
body textarea:-webkit-autofill:hover,
body textarea:-webkit-autofill:focus,
body select:-webkit-autofill,
body select:-webkit-autofill:hover,
body select:-webkit-autofill:focus {
  border: solid 1px #ced4da;
  -webkit-text-fill-color: #000;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

body .ant-dropdown-menu-item:hover,
body .ant-dropdown-menu-submenu-title:hover {
  background: rgba(34, 108, 160, 0.05);
}

body .ant-breadcrumb {
  background: #fff;
  padding: 10px 40px;
  -webkit-box-shadow: 0 0px 8px #e9ecef;
          box-shadow: 0 0px 8px #e9ecef;
}

body .ant-breadcrumb a:hover {
  color: #226ca0;
}

body .ant-table-thead {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

body .ant-table-thead tr > th {
  padding: 10px 16px;
  font-weight: 600 !important;
  font-size: 13px;
  color: #212121;
}

body .ant-table-body tr:hover td {
  background: rgba(34, 108, 160, 0.05) !important;
}

body .ant-table-body td.numbers {
  font-size: 14px;
}

body .ant-table-body td {
  color: #495057;
  padding: 20px 16px;
}

body .ReactTable {
  border: none;
}

body .ReactTable.-striped .rt-tr.-odd {
  background: #fff;
}

body .ReactTable .rt-thead.-header {
  -webkit-box-shadow: none;
          box-shadow: none;
}

body .ReactTable .rt-thead .rt-th,
body .ReactTable .rt-thead .rt-td {
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  border-right: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 16px 5px;
  font-weight: 500 !important;
}

body .ReactTable .rt-td {
  font-size: 14px;
  padding: 10px;
}

body .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #e8e8e8;
}

body .ReactTable .rt-tbody .rt-tr-group .rt-td {
  border-right: none;
}

body .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .ReactTable .rt-thead .rt-th.-sort-asc,
body .ReactTable .rt-thead .rt-td.-sort-asc {
  -webkit-box-shadow: inset 0 3px 0 0 rgba(34, 108, 160, 0.6);
          box-shadow: inset 0 3px 0 0 rgba(34, 108, 160, 0.6);
}

body .ReactTable .rt-thead .rt-th.-sort-desc,
body .ReactTable .rt-thead .rt-td.-sort-desc {
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(34, 108, 160, 0.6);
          box-shadow: inset 0 -3px 0 0 rgba(34, 108, 160, 0.6);
}

body .ReactTable div[contenteditable="true"] {
  background: #fff !important;
  border-radius: 4px;
  border: solid 1px #fff;
  margin: 0 5px;
  padding: 5px 0;
}

body .ReactTable div[contenteditable="true"]:focus {
  outline: none;
  border: solid 1px #17a2b8;
}

body .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(34, 108, 160, 0.05) !important;
}

body .ant-card {
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 8px 0 #e0e0e0;
          box-shadow: 0 2px 8px 0 #e0e0e0;
}

body .ant-steps {
  max-width: 80%;
  margin: 0 auto;
}

body .ant-steps-item {
  position: relative;
}

body .ant-steps-item-icon {
  display: none;
}

body .ant-steps-item-content {
  text-align: center;
  position: relative;
}

body .ant-steps-item-title {
  font-size: 14px;
  color: #616161;
  position: unset;
}

body .ant-steps-item-title:after {
  left: 110%;
  top: 0;
  bottom: 0;
  margin: auto;
}

body .ant-steps-item-description {
  font-weight: bold;
  font-size: 18px;
  color: #212121;
  text-align: center;
}

body .ant-steps .ant-steps-item-wait
> .ant-steps-item-container
> .ant-steps-item-content
> .ant-steps-item-description {
  color: #495057;
}

body .ant-steps .ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-content
> .ant-steps-item-description {
  color: #226ca0;
}

body .ant-steps .ant-steps-item-finish
> .ant-steps-item-container
> .ant-steps-item-content
> .ant-steps-item-title::after {
  background: #226ca0;
}

body.login {
  background: url("./assets/login-bg.jpg") no-repeat center center;
  background-size: cover;
}

body.login .ant-card {
  max-width: 400px;
  background: #FFFFFF;
  border: 4px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
}

body.login h3 {
  font-weight: bold;
  font-size: 18px;
  color: #F40000;
  letter-spacing: 0;
  text-align: center;
  line-height: 26px;
}

body.login .ant-btn.ant-btn-primary {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

body.login img.login-logo {
  max-width: 100%;
  display: block;
}

body.login .poweredby {
  color: #6c757d;
  text-transform: uppercase;
}

body.login .poweredby img {
  max-width: 100px;
  margin-left: 10px;
}

body {
  background: #EEEEEE;
}

body #root .ant-layout {
  background: none;
}

a {
  color: #226ca0;
}

a:hover, a:focus, a:active {
  color: #1e5e8b;
}

.outer-wrapper {
  margin: 15px 16px 24px;
  padding: 24px;
  min-height: 300px;
}

.outer-wrapper.card {
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 5px;
}

body .main-header {
  background: #226ca0;
  padding: 0 40px;
}

body .main-header .ant-menu {
  background: transparent;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .main-header .ant-menu.ant-menu-horizontal > .ant-menu-item {
  border: none !important;
}

body .main-header .ant-menu li {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 15px;
  font-size: 15px;
  color: #FFFFFF;
  border: none;
}

body .main-header .ant-menu li a {
  color: rgba(255, 255, 255, 0.9);
  border: none;
}

body .main-header .ant-menu li a:hover, body .main-header .ant-menu li a:focus, body .main-header .ant-menu li a:active {
  color: #fff;
  border: none;
}

body .main-header .ant-menu li i {
  color: rgba(255, 255, 255, 0.9);
}

body .main-header .ant-menu li:hover i, body .main-header .ant-menu li:focus i, body .main-header .ant-menu li:active i {
  color: white;
}

body .main-header .creat-btn {
  background: rgba(255, 255, 255, 0.4);
  border: none;
  color: #fff;
  padding: 0 15px;
  line-height: 36px;
  border-radius: 5px;
  font-weight: 700;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

body .main-header .creat-btn:hover, body .main-header .creat-btn:focus, body .main-header .creat-btn:active {
  background: #fff;
  color: #226ca0 !important;
}

.notification-dropdown ul {
  min-width: 250px;
}

.notification-dropdown ul li {
  padding: 15px 10px;
  border-bottom: solid 1px #ced4da;
}

.notification-dropdown ul li:last-child {
  border-bottom: none;
}

.notification-dropdown ul li span.date {
  display: block;
}

.session-card {
  background: #FFFFFF;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 20px 20px 10px 20px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.session-card:hover {
  -webkit-box-shadow: 0 2px 10px 1px #dee2e6;
          box-shadow: 0 2px 10px 1px #dee2e6;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.session-card-title {
  font-size: 18px;
  color: #212529;
}

.session-card-desc {
  font-size: 14px;
  color: #6c757d;
  line-height: 20px;
  margin-bottom: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.session-card-data {
  border-top: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  padding: 10px 0;
  margin-bottom: 15px;
}

.session-card-data div {
  text-align: center;
  width: 60%;
  padding: 15px 0;
}

.session-card-data div:first-child {
  border-right: solid 1px #dee2e6;
  width: 40%;
}

.session-card-data span {
  display: block;
}

.session-card-data .number {
  font-size: 15px;
  color: #226ca0;
  text-align: center;
  font-weight: bold;
}

.session-card-data .text {
  font-size: 14px;
  color: #9E9E9E;
  text-align: center;
}

.session-card-meta {
  font-size: 13px;
}

.session-card-meta a {
  font-weight: bold;
  font-size: 14px;
}

.session-title {
  margin-bottom: 0;
}

.session-title span {
  margin-top: 5px;
  display: block;
  font-size: 13px;
  color: #6c757d;
  letter-spacing: 0;
  font-weight: normal;
}

.session-count {
  padding: 4px 5px;
  border-radius: 4px;
  color: #fff;
}

.text-danger .session-count {
  background: #226ca0;
}

.text-success .session-count {
  background: #86d295;
}

.text-dark .session-count {
  background: #fff;
  border: solid 1px #ced4da;
  color: #343a40;
}

.preview-form input {
  pointer-events: none;
  border: none;
  font-weight: bold;
  color: #212529;
  padding: 0;
}

.terminal {
  background-color: black;
  background-image: radial-gradient(rgba(0, 150, 0, 0.75), black 120%);
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 2rem;
  color: white;
  font: monospace;
  position: relative;
}

.terminal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 1px, transparent 1px, transparent 2px);
  pointer-events: none;
}

::-moz-selection {
  background: #0080ff;
  text-shadow: none;
}

::selection {
  background: #0080ff;
  text-shadow: none;
}

pre {
  margin: 0;
}

.preview {
  margin-bottom: 24px;
}

.preview span {
  display: block;
  overflow-wrap: break-word;
}

.preview .title {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}

.preview .value {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  letter-spacing: 0;
  line-height: 1.8;
}

.bg-white {
  background: #fff !important;
}

.custom-radio .ant-radio-button-wrapper {
  font-weight: 600;
  padding-left: 40px;
}

.custom-radio .ant-radio-button-wrapper:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  -webkit-box-shadow: 0 2px 4px 0 #E0E0E0;
          box-shadow: 0 2px 4px 0 #E0E0E0;
  border-radius: 4px;
}

.custom-radio .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)::before {
  background: #fff !important;
  opacity: 0.5;
}

.custom-radio .ant-radio-button-wrapper .ant-radio-button:before {
  content: "\2713";
  position: absolute;
  left: 14px;
  font-size: 14px;
  opacity: 0;
}

.custom-radio .ant-radio-button-wrapper .ant-radio-button-checked:before {
  opacity: 1;
}

.custom-radio .ant-radio-button-wrapper:hover {
  color: #86d295;
}

.custom-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #86d295;
  color: #86d295;
  -webkit-box-shadow: -1px 0 0 0 #86d295;
          box-shadow: -1px 0 0 0 #86d295;
}

.custom-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background: #FFFFFF;
  border: 1px solid #86D295;
  -webkit-box-shadow: 0 2px 4px 0 rgba(134, 210, 149, 0.3);
          box-shadow: 0 2px 4px 0 rgba(134, 210, 149, 0.3);
  border-radius: 4px;
  opacity: 1;
}

.custom-radio .ant-radio-group {
  width: 100%;
}

.custom-radio .ant-radio-group .ant-radio-button-wrapper {
  width: 50%;
}

.custom-radio.moo .ant-form-item-label > label {
  color: #17a2b8;
}

.custom-radio.ibc .ant-form-item-label > label {
  color: #ff6720;
}

.ReactTable input, .ReactTable select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: 400;
  outline: none;
}

body .terminal {
  background-color: #000;
  background-image: none;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 2rem;
  color: #fff;
  font: 1.3rem Inconsolata,monospace;
  text-shadow: 0 0 5px #c8c8c8;
  max-height: 200px;
}

body .swal-button--danger {
  background: #226ca0;
}

body .swal-button--danger:hover, body .swal-button--danger:active, body .swal-button--danger:not([disabled]):hover {
  background: #195076;
}
