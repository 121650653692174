.help-section {
  .process-flow {
    background: $primary;
    border-radius: 4px;
    margin-bottom: 100px;
    padding: 50px 0;
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    &.no-arrow {
      margin-bottom: 0;

      &:before,
      &:after {
        display: none;
      }
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $primary;
      position: absolute;
      bottom: -100px;
      left: 45%;
    }

    &:after {
      content: '';
      position: absolute;
      left: 49%;
      background: $primary;
      width: 20px;
      height: 100px;
      bottom: -90px;
    }
  }

  .check-list {
    font-size: 16px;
    list-style: none;

    li {
      padding-bottom: 15px;
    }
  }

  table {
    font-size: 16px;

    thead th {
      background: rgba($color: $primary, $alpha: 1);
      color: #fff;
    }

    td,
    th {
      padding: 15px;
      border-bottom: solid 1px $gray-400;
    }

    tr td {
      font-size: 14px;
      vertical-align: top;
    }

    tr td:first-child,
    tr td.bg {
      background: $gray-200;
      font-weight: bold;
    }

    &.va-center tr td {
      vertical-align: middle;
    }
  }

  .iphen {
    display: block;
    position: relative;
    padding-left: 25px;

    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 1px;
      background: rgba(0, 0, 0, 0.65);
      left: 0;
      top: 10px;
    }
  }

  .circle {
    width: 140px;
    height: 100px;
    background: $primary;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    justify-content: center;
    border: solid 2px #fff;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .ant-menu-submenu-selected {
    color: $primary;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: $primary;
    background-color: rgba($color: $primary, $alpha: 0.08);

    &:after {
      border-right: 3px solid $primary;
    }
  }
}
