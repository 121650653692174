body.login {
  background: url('../assets/login-bg.jpg') no-repeat center center;
  background-size: cover;

  .ant-card {
    max-width: 400px;
    background: #ffffff;
    border: 4px solid rgba(255, 255, 255, 0.25);
    // box-shadow: 0 0 20px 10px rgba($black, 0.5);
    border-radius: 6px;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    color: #f40000;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
  }

  .ant-btn.ant-btn-primary {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }

  img.login-logo {
    max-width: 100%;
    display: block;
  }

  .poweredby {
    color: $gray-600;
    text-transform: uppercase;

    img {
      max-width: 100px;
      margin-left: 10px;
    }
  }
}
