.terminal {
  background-color: black;
  background-image: radial-gradient(rgba(208, 179, 96, 0.75));
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 2rem;
  color: white;
  font: 1.3rem Inconsolata, monospace;
  text-shadow: 0 0 5px #c8c8c8;
  max-height: 200px;
}
.terminal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 1px,
    transparent 1px,
    transparent 1px
  ); */
  pointer-events: none;
}
::selection {
  background: #0080ff;
  text-shadow: none;
}
pre {
  margin: 0;
}
